import React from "react";
import { createRoot } from "react-dom/client";
import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";
import Amplify from "aws-amplify";
import { Provider } from "react-redux";
import { auth } from "./config.json";
import { Provider as ThemeProvider } from "./theming";
import { store } from "./store";
import { GlobalErrorHandler } from "@swoop/ui/lib/GlobalErrorHandler";
import { Authenticator } from "@swoop/login";
import { logError } from "./lib/api";
import config from "./config.json";

import "./assets/index.css";

Amplify.configure({ Auth: auth, Analytics: { disabled: true } });

const container = document.getElementById("root") as HTMLElement;

const root = createRoot(container);

root.render(
    <React.StrictMode>
        <GlobalErrorHandler store={store} logError={logError} recordEvents>
            <Provider store={store}>
                <Authenticator config={config.auth}>
                    <ThemeProvider>
                        <Routes />
                    </ThemeProvider>
                </Authenticator>
            </Provider>
        </GlobalErrorHandler>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
