import React from "react";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        maxHeight: "100vh"
    }
});

const AppContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
    const classes = useStyles();

    return <div className={classes.container}>{children}</div>;
};

export default AppContainer;
