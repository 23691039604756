import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { userManagementReducers, userManagementMiddlewares } from "@swoop/login/lib/Modals/UserManagement/reducer";

const combinedReducers = combineReducers({
    ...userManagementReducers
});

export const store = configureStore({
    reducer: combinedReducers,
    devTools: true,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredPaths: ["user.user.auth", "user.user.exp"]
            }
        }).concat(...userManagementMiddlewares)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
