import React, { useEffect, useState } from "react";
import { Footer } from "@swoop/ui/lib/Footer";
import AppBar from "@swoop/ui/lib/AppBar";
import GitCommit from "../_git_commit";
import Grid from "@mui/material/Grid";
import Body from "../component/Body";
import AppContainer from "../component/AppContainer";
import { getCurrentUser, logout } from "../lib/auth";
import { UserDetailType } from "../types";
import { UserManagementModal } from "@swoop/login/lib/Modals/UserManagement";
import { GlobalErrorHandler } from "src/component/GlobalErrorHandler";

const Layout: React.FC = () => {
    const [user, setUser] = useState<UserDetailType | null | undefined>(null);

    useEffect(() => {
        getCurrentUser().then(data => {
            setUser(data);
        });
    }, []);

    return (
        <AppContainer>
            <GlobalErrorHandler>
                <AppBar
                    user={user}
                    onSignout={logout}
                    extraInfoComponent={() => (
                        <Grid container justifyContent={"center"}>
                            <UserManagementModal showButton />
                        </Grid>
                    )}
                />
                <Body />
                <Footer version={(GitCommit && GitCommit.logMessage && GitCommit.logMessage.split(" ")[0]) || ""} />
            </GlobalErrorHandler>
        </AppContainer>
    );
};

export default Layout;
