import React from "react";
import { ThemeProvider, createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#000000",
            light: "#000000",
            dark: "#000000"
        },
        secondary: {
            main: "#16B7BA",
            light: "#63EAEC",
            dark: "#00878A"
        }
    },
    typography: {
        h6: {
            fontFamily: "Sora, sans-serif",
            fontWeight: 400,
            fontSize: 20,
            letterSpacing: 0.15,
            lineHeight: "160%"
        }
    }
});

export const Provider: React.FC<React.PropsWithChildren> = ({ children }) => (
    <ThemeProvider theme={theme}> {children}</ThemeProvider>
);
