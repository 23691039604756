import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { CardItem } from "./CardItem";

import {
    LookoutIcon,
    AdminIcon,
    LogViewerIcon,
    ATMIcon,
    FleetIcon,
    OPSIcon,
    PlannerIcon,
    RPSIcon,
    RPS2Icon,
    LogisticIcon,
    SimulatorIcon,
    CreatorIcon
} from "src/assets/icons/new";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { Theme } from "@mui/material";

type GridSectionsType = {
    applications: string[];
};

const useStyles = makeStyles((theme: Theme) => ({
    grid: {
        flexDirection: "row",
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },
    greeting: {
        fontFamily: "Sora",
        fontWeight: "bold",
        marginBottom: 40,
        paddingLeft: 25,
        [theme.breakpoints.down("md")]: {
            marginBottom: "10px"
        },
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    cardsContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: "1260px",
        margin: "50px auto 0px auto",
        [theme.breakpoints.down("sm")]: {
            margin: "15px"
        }
    }
}));

export const GridSections: React.FC<GridSectionsType> = ({ applications = [] }) => {
    const classes = useStyles();

    if (applications.length) {
        return (
            <>
                <Box className={classes.cardsContainer}>
                    <Typography variant="h4" align="left" className={classes.greeting}>
                        Welcome to Swoop Aero
                    </Typography>
                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                        {applications
                            .filter(app => {
                                return app !== "Platform";
                            })
                            .map(app => {
                                switch (app) {
                                    case "Admin":
                                        return (
                                            <Grid item key={app} className={classes.grid}>
                                                <CardItem
                                                    title="Admin"
                                                    subtitle="User administrations"
                                                    icon={<AdminIcon />}
                                                    href="/admin"
                                                />
                                            </Grid>
                                        );
                                    case "OPS":
                                        return (
                                            <Grid item key={app} className={classes.grid}>
                                                <CardItem
                                                    icon={<OPSIcon />}
                                                    title="Operations"
                                                    href="/ops"
                                                    subtitle="Operations management"
                                                />
                                            </Grid>
                                        );
                                    case "Planner":
                                        return (
                                            <Grid item key={app} className={classes.grid}>
                                                <CardItem
                                                    icon={<PlannerIcon />}
                                                    title="Planner"
                                                    href="/planner"
                                                    subtitle="Flight planning"
                                                />
                                            </Grid>
                                        );
                                    case "RPS":
                                        return (
                                            <>
                                                <Grid item key={app} className={classes.grid}>
                                                    <CardItem
                                                        icon={<RPSIcon />}
                                                        title="RPS - Kooka"
                                                        href="/rps"
                                                        subtitle="Remote Pilot Station - Kookaburra"
                                                    />
                                                </Grid>

                                                <Grid item key={app} className={classes.grid}>
                                                <CardItem
                                                    icon={<RPS2Icon />}
                                                    title="RPS - Kite"
                                                    href="/rps2"
                                                    subtitle="Remote Pilot Station - Kite"
                                                />
                                                </Grid>
                                            </>
                                        );

                                        
                                    case "LogViewer":
                                        return (
                                            <Grid item key={app} className={classes.grid}>
                                                <CardItem
                                                    icon={<LogViewerIcon />}
                                                    title="Analytics"
                                                    href="/logviewer"
                                                    subtitle="Post-flight data"
                                                />
                                            </Grid>
                                        );
                                    case "ATM":
                                        return (
                                            <Grid item key={app} className={classes.grid}>
                                                <CardItem
                                                    icon={<ATMIcon />}
                                                    title="ATM"
                                                    href="/atm"
                                                    subtitle="Aircraft traffic management"
                                                />
                                            </Grid>
                                        );
                                    case "Fleet":
                                        return (
                                            <Grid item key={app} className={classes.grid}>
                                                <CardItem
                                                    icon={<FleetIcon />}
                                                    title="Fleet"
                                                    href="/fleet"
                                                    subtitle="Fleet digital shadow"
                                                />
                                            </Grid>
                                        );
                                    case "Lookout":
                                        return (
                                            <Grid item key={app} className={classes.grid}>
                                                <CardItem
                                                    icon={<LookoutIcon />}
                                                    title="Lookout"
                                                    href="/lookout"
                                                    subtitle="Remote observation"
                                                />
                                            </Grid>
                                        );
                                    case "Logistics":
                                        return (
                                            <Grid item key={app} className={classes.grid}>
                                                <CardItem
                                                    icon={<LogisticIcon />}
                                                    title="Logistics"
                                                    href="/logistics"
                                                    subtitle="Consignment track & trace"
                                                />
                                            </Grid>
                                        );
                                    case "Simulator":
                                        return (
                                            <Grid item key={app} className={classes.grid}>
                                                <CardItem
                                                    icon={<SimulatorIcon />}
                                                    title="Simulator"
                                                    href="/simulator"
                                                    subtitle="Flight training"
                                                />
                                            </Grid>
                                        );
                                    case "Creator":
                                        return (
                                            <Grid item key={app} className={classes.grid}>
                                                <CardItem
                                                    icon={<CreatorIcon />}
                                                    title="Creator"
                                                    href="/creator"
                                                    subtitle="Platform manufacturing"
                                                />
                                            </Grid>
                                        );
                                    default:
                                        return <></>;
                                }
                            })}
                    </Grid>
                </Box>
            </>
        );
    } else {
        return null;
    }
};
