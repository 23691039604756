import React from "react";
import { Card, CardContent, CardHeader, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: 223,
        height: 223,
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#f6f6f6"
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            flexDirection: "row",
            height: 104,
            "& > div": {
                padding: 21,
                flex: 1,
                "&:last-child": {
                    paddingLeft: 0
                }
            }
        }
    },
    content: {
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
        "& > svg": {
            height: 100,
            [theme.breakpoints.down("sm")]: {
                height: 75
            }
        }
    },
    link: {
        textDecoration: "none",
        display: "flex",
        justifyContent: "center"
    }
}));

type IProps = {
    title: string;
    subtitle: string;
    icon: React.ReactNode;
    href: string;
    target?: string;
};

export const CardItem: React.FC<IProps> = ({ icon, title, subtitle, href, target }) => {
    const classes = useStyles();

    return (
        <a href={href} target={target} className={classes.link}>
            <Card className={classes.container} elevation={6}>
                <CardHeader
                    title={
                        <Typography variant="h6" align="left">
                            {title}
                        </Typography>
                    }
                    subheader={
                        <Typography variant="body2" align="left" color="textSecondary">
                            {subtitle}
                        </Typography>
                    }
                />
                <CardContent className={classes.content}>{icon}</CardContent>
            </Card>
        </a>
    );
};
