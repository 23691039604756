import React from "react";

interface IState {
    hasError: boolean;
}

export class GlobalErrorHandler extends React.Component<{ children: React.ReactNode }, IState> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: any, info: any) {
        console.log({ error, info });
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }
        return this.props.children;
    }
}
