import { Auth } from "aws-amplify";
import { UserDetailType } from "src/types";

const getToken = async () => {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
};

const hasRole = async (rolename: string): Promise<boolean> => {
    const session = await Auth.currentSession(),
        payload = session.getIdToken().decodePayload(),
        groups = payload["cognito:groups"];

    if (groups && groups.length > 0 && groups.includes(rolename)) {
        return true;
    } else {
        return false;
    }
};

const verifyRole = async (rolename: string) => {
    if (!(await hasRole(rolename))) {
        window.location.pathname = "/permissiondenied";
    }
};

const hasRegion = async (region: string) => {
    const regions = await getRegions();
    return regions.includes(region);
};

export const getRegions = async (): Promise<string[]> => {
    const session = await Auth.currentSession(),
        payload = session.getIdToken().decodePayload();
    return parseRegions(payload["custom:regions"]);
};

const parseRegions = (regions: string): string[] => {
    if (regions) {
        return regions.split(",").map(r => r.trim());
    }
    return [];
};

const logout = () => {
    Auth.signOut();
    window.location.href = "/";
};

const getCurrentUser = async (): Promise<UserDetailType | null> => {
    try {
        const payload = (await Auth.currentSession()).getIdToken().decodePayload();
        return {
            groups: payload["cognito:groups"] ? payload["cognito:groups"] : [],
            email: payload.email,
            phone: payload.phone_number,
            auth: new Date(payload.auth_time * 1000),
            exp: new Date(payload.exp * 1000),
            username: payload["cognito:username"],
            firstname: payload.given_name,
            lastname: payload.family_name
        };
    } catch (e) {
        return null;
    }
};

export { hasRole, getToken, verifyRole, logout, getCurrentUser, hasRegion };
