import React from "react";
import Layout from "./container/Layout";
import PermissionDenied from "./container/PermissionDenied";
import { Switch, Route, BrowserRouter as Router, Redirect } from "react-router-dom";

const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route path="/" exact component={Layout} />
                {/* fake login path */}
                <Route
                    path="/login"
                    exact
                    component={() => {
                        console.log("INFO: in /login Redirect");
                        return <Redirect to="/" />;
                    }}
                />
                <Route exact path="/permissiondenied" render={props => <PermissionDenied {...props} />} />
            </Switch>
        </Router>
    );
};

export default Routes;
