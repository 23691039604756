import React from "react";
import AppContainer from "src/component/AppContainer";
import { AppBar } from "@swoop/ui/lib/AppBar";
import { Footer } from "@swoop/ui/lib/Footer";
import PermissionDenied from "@swoop/ui/lib/Pages/PermissionDenied";
import { logout } from "src/lib/auth";
import GitCommit from "../_git_commit";

const PermissionDeniedComp: React.FC = () => {
    return (
        <AppContainer>
            <AppBar onSignout={logout} />
            <PermissionDenied />
            <Footer version={(GitCommit && GitCommit.logMessage && GitCommit.logMessage.split(" ")[0]) || ""} />
        </AppContainer>
    );
};

export default PermissionDeniedComp;
