import axios, { AxiosRequestConfig } from "axios";
import config from "../config.json";
import { getToken } from "./auth";

export const logError = async (
    data: any
): Promise<{
    data: any;
}> => {
    return await post(`/log/error`, data, "maintenance");
};

const post = async (path: string, data: any, type: any) => {
    return await call(await createRequest("POST", path, data, type));
};

const createRequest = async (method: string, path: string, data: any, type: string) => {
    const apiurl = config.apiUrl + "/" + type;
    const param: any = {
        method,
        // default to ops apig
        url: apiurl + path,
        headers: { Authorization: await getToken() }
    };
    if (data) {
        param.data = JSON.stringify(data);
    }

    return param;
};

const call = async (params: AxiosRequestConfig) => {
    try {
        const response = await axios(params);
        return response.data;
    } catch (e) {
        return { status: 500, message: JSON.stringify(e) };
    }
};
