import React, { useEffect, useState } from "react";
import { GridSections } from "./GridSections";

import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

import decode from "jwt-decode";
import { getToken } from "../../lib/auth";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        padding: 50,
        flex: 1,
        overflowY: "auto",
        [theme.breakpoints.down("sm")]: {
            padding: 22
        },
        backgroundColor: "#E0E0E0"
    }
}));

const Body: React.FC = () => {
    const classes = useStyles(),
        [applications, setApplications] = useState([]);

    useEffect(() => {
        getToken()
            .then(token => {
                try {
                    const { details } = decode(token) as { details: string },
                        { applications } = JSON.parse(details);
                    setApplications(applications);
                } catch (e) {
                    console.log(e);
                }
            })
            .catch(e => {
                console.log(e);
            });
    }, []);

    return (
        <div className={classes.root}>
            <GridSections applications={applications} />
        </div>
    );
};

export default Body;
